import React from "react";
import service_1 from "../../assets/service_1.jpg";
import service_2 from "../../assets/service_2.jpg";
import service_3 from "../../assets/service_3.jpg";
import Form from "./Form";
import Tags from "./Tags";
import { FaCheckCircle, FaFontAwesome, FaSearch } from "react-icons/fa";

const CardSection = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center gap-12 my-7">
      <div className="flex flex-col w-full md:w-1/2 mt-20 mx-auto">
        <div className="mx-4">
          <img src={service_1} alt="Service1" />
        </div>
        <div className="flex flex-col gap-y-3 mt-6 mx-4">
          <p className=" text-3xl md:text-5xl font-serif font-medium">
            Body Massages
          </p>
          <p className=" text-md text-gray-500 text-justify">
            Body massages offer a diverse range of therapeutic techniques
            tailored to meet various wellness needs. From the soothing strokes
            of a Swedish massage to the targeted pressure of a deep tissue
            massage for chronic tension relief, each style provides a distinct
            approach to enhancing both physical and mental well-being. Hot stone
            massages use heated stones for a deeply relaxing experience, while
            aromatherapy massages blend essential oils for a sensory journey.
            Thai massages combine acupressure and yoga-like stretches, and
            sports massages focus on muscle recovery for athletes.
          </p>
          <p className="text-md text-gray-500 text-justify">
            Prenatal massages cater to expectant mothers, reflexology targets
            specific points on the feet, hands, or ears, and lymphatic drainage
            massages promote improved immune function. Couples massages offer a
            shared, intimate experience, while trigger point massages address
            specific areas of muscle tension. Indian head massages focus on the
            head, neck, and shoulders, and hot oil massages utilize warm oils to
            enhance relaxation and nourish the skin. Whether seeking relaxation,
            pain relief, or targeted therapeutic benefits, there's a massage
            style to suit every individual's preferences and needs.
          </p>
        </div>
        <div className="flex flex-col gap-y-3 mt-6 mx-4">
          <p className="md:text-3xl  font-serif font-medium">
            Benefit For Body Massage
          </p>
          <p className=" text-md text-gray-500 text-justify">
            Body massages offer a multitude of benefits that extend beyond mere
            relaxation. The therapeutic touch of a massage promotes stress
            reduction by easing tense muscles and releasing endorphins,
            contributing to an improved mood and overall mental well-being.
            Enhanced blood circulation is another key advantage, fostering
            better oxygen flow to tissues and promoting quicker muscle recovery.
            Regular massages have been associated with reduced muscle stiffness,
            increased joint flexibility, and improved posture.
          </p>
        </div>
        <div className="flex flex-col md:flex-row mt-6 gap-12">
          <div className="flex flex-row items-center gap-3 mx-auto">
            <FaCheckCircle />
            <p>Best For Human Health</p>
          </div>
          <div className="flex flex-row items-center gap-3 mx-auto">
            <FaCheckCircle />
            <p>Always Be Strong & Motivate</p>
          </div>
          <div className="flex flex-row items-center gap-3 mx-auto">
            <FaCheckCircle />
            <p>Hair loss prevention</p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-7 mt-6 mx-4">
          <img src={service_2} alt="Service2" />
          <img src={service_3} alt="Service3" />
        </div>
        <div className="mt-6 mx-4">
          <p className="text-md text-gray-500 text-justify">
            Additionally, they can alleviate chronic pain conditions, such as
            lower back pain or migraines, by targeting specific pressure points.
            The nurturing touch of a skilled therapist also promotes better
            sleep, lowers anxiety levels, and boosts the immune system. Overall,
            body massages provide a holistic approach to wellness, addressing
            physical, mental, and emotional aspects for a rejuvenated and
            balanced state of being.
          </p>
        </div>
        <div className="flex flex-col gap-y-3 mt-6 mx-4">
          <p className="md:text-3xl font-serif font-medium">
            Massage Techniques
          </p>
          <p className="text-md text-gray-500 text-justify">
            Massage therapy encompasses a diverse range of techniques tailored
            to address various wellness needs. Swedish massage, with its flowing
            strokes, promotes relaxation and improved circulation, while deep
            tissue massage targets deeper muscle layers for tension relief. Hot
            stone massage employs heated stones for enhanced relaxation, and
            aromatherapy massage combines essential oils for a sensory
            experience. Thai massage involves stretching and acupressure, sports
            massage aids athletes in recovery, and prenatal massage caters to
            expectant mothers.
          </p>
        </div>
      </div>
      <div className="flex flex-col mx-auto">
        <div className="bg-swipeToBgColor rounded-md p-16 shadow-md mb-4 mt-8 mx-2">
          <div className="flex flex-col ">
            <div>
              <p className="font-serif text-2xl">Categories</p>
              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[3px] bg-black"></div>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Body Treatments</p>
                </div>
                <p>(8)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] bg-gray-400"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Facials</p>
                </div>
                <p>(6)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] bg-gray-400"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Massages</p>
                </div>
                <p>(5)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] bg-gray-400"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Prenatal & Postnatal Services</p>
                </div>
                <p>(7)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] bg-gray-400"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Wellness & Fitness</p>
                </div>
                <p>(2)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] bg-gray-400"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-3 items-center">
                  <FaFontAwesome />
                  <p>Specialized Packages</p>
                </div>
                <p>(9)</p>
              </div>

              <div className="flex w-full  mt-3  justify-center">
                <div className="w-full  h-[1px] bg-gray-400"></div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='mx-2'>
                    <Form />
                </div> */}

        <div className="mx-2">
          <Tags />
        </div>

        <div className="bg-swipeToBgColor rounded-md p-4 md:py-8 md:px-8 shadow-md mb-4 mt-8">
          <div className="flex flex-col items-center">
            <p className="font-serif text-2xl mb-3">Features</p>
            <div className="flex w-full mt-2 justify-center">
              <div className="w-full h-1 bg-black"></div>
            </div>
          </div>

          <div className="flex flex-col justify-center mt-3">
            <div className="mt-3 flex flex-wrap justify-center">
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 
                    mb-2 transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Massage Services
              </button>
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Facial Treatments
              </button>
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Body Treatments
              </button>
            </div>

            <div className="mt-3 flex flex-wrap justify-center">
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Nail Care Services
              </button>
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Hair Services
              </button>
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Hydrotherapy
              </button>
            </div>

            <div className="mt-3 flex flex-wrap justify-center">
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 
                    mb-2 transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Wellness Classes
              </button>
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Skin Care Services
              </button>
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                AromaTherapy
              </button>
            </div>

            <div className="mt-3 flex flex-wrap justify-center">
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Relaxation Area
              </button>
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
              >
                Couple Packages
              </button>
              <button
                className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
              >
                Medical Spa Services
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSection;
