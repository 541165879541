import React, { useState } from "react";
import { FaDotCircle } from "react-icons/fa";

const cardsection = [
  {
    heading: "Swedish Massage",
    para: "A gentle, full-body massage using long strokes and kneading techniques to promote relaxation.",
  },
  {
    heading: "Hot Stone Massage",
    para: "Involves the use of heated stones to enhance relaxation and ease muscle stiffness.",
  },
  {
    heading: "Body Wraps",
    para: "The body is wrapped in nourishing substances (such as mud or seaweed) to detoxify and moisturize the skin.",
  },
  {
    heading: "Hydrotherapy Baths",
    para: "Immersing the body in water with added elements like essential oils, salts, or herbs for therapeutic benefits.",
  },
  {
    heading: "Relaxation Rooms",
    para: "Quiet spaces designed for relaxation before or after treatments.",
  },
  {
    heading: "Steam Rooms",
    para: " Using steam to open pores, detoxify the body, and promote relaxation.",
  },
  {
    heading: "Saunas",
    para: "Dry heat rooms that induce sweating and help relax muscles.",
  },
  {
    heading: "Slimming Treatments",
    para: "Aimed at reducing inches and toning the body.",
  },
  {
    heading: "Foot Reflexology",
    para: "Involves applying pressure to specific points on the feet believed to correspond to different organs and systems of the body.",
  },
];
const Card = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const maxSlides = 4;

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % maxSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + maxSlides) % maxSlides);
  };
  return (
    <div>
      <div className="flex flex-col items-center mx-4">
        <div className="flex flex-col lg:flex-row gap-5 items-center">
          {cardsection
            .slice(currentSlide, currentSlide + maxSlides)
            .map((element, index) => (
              <div
                className="bg-gradient-to-r from-primaryBgColor to-swipeToBgColor p-4 rounded-md shadow-md w-full lg:w-[300px] h-[200px] mb-4 lg:mb-0"
                key={index}
              >
                <h2 className="text-xl font-semibold text-center mx-auto mt-8">
                  {element.heading}
                </h2>
                <p className="text-gray-600 text-center mx-auto">
                  {element.para}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Card;
