import React from 'react';
import gallery_1 from '../../assets/gallery_1.jpg';
import gallery_2 from '../../assets/gallery_2.jpg';
import gallery_3 from '../../assets/gallery_3.jpg';
import gallery_4 from '../../assets/gallery_4.jpg';

const Cards = () => {
    return (
        <div>
            <div className='flex flex-col md:flex-row gap-8 justify-center items-center mx-auto overflow-x-auto'>
                <div className='mx-4'>
                    <img src={gallery_1} alt="Gallery_1" className='w-full h-auto' />
                </div>
                <div className='flex flex-col'>
                    <div className='mx-4'>
                        <img src={gallery_2} alt="Gallery_2" className='w-full h-auto' />
                    </div>

                    <div className='flex flex-col md:flex-row mt-8 gap-6 justify-center'>
                        <div className='mx-4'>
                            <img src={gallery_3} alt="Gallery_3" />
                        </div>
                        
                        <div className='mx-auto'>
                            <img src={gallery_4} alt="Gallery_4"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Cards