import React from "react";
import Cards from "../components/Homepage/Cards";
import women from "../assets/wmn.png";
import CTAButton from "../components/Homepage/Button";
import { FaArrowRight } from "react-icons/fa";
import shape from "../assets/shape.svg";
import Card from "../components/Homepage/Card";
import massage from "../assets/spas1.jpg";
import girl from "../assets/girl.jpg";
import rightarr from "../assets/rightarr.svg";
import badge from "../assets/badge.svg";
import leaf from "../assets/leaf.svg";
import like from "../assets/like.svg";
import user from "../assets/user.svg";
import girls from "../assets/girls.svg";
import Expert from "../components/Homepage/Expert";
import Form from "../components/Homepage/Form";
import brand_1 from "../assets/brand_1.png";
import brand_2 from "../assets/brand_2.png";
import brand_3 from "../assets/brand_3.png";
import brand_4 from "../assets/brand_4.png";
import CardSection from "../components/Homepage/CardSection";
import PriceCard from "../components/Homepage/PriceCard";
import formimage from "../assets/formImage.png";
import "./HomePage.css";

const starsComponent = () => {
  return (
    <div>
      {[1, 2, 3, 4, 5, 6].map(() => (
        <>
          <div
            className="star star-blue"
            style={{
              animation: "blowStars 4s linear infinite",
              animationDelay: "1s",
            }}
          ></div>
          <div
            className="star star-yellow"
            style={{
              animation: "blowStars 5s linear infinite",
              animationDelay: "3s",
            }}
          ></div>
          <div
            className="star star-white"
            style={{
              animation: "blowStars 6s linear infinite",
              animationDelay: "2s",
            }}
          ></div>
          <div
            className="star star-pink"
            style={{
              animation: "blowStars 7s linear infinite",
              animationDelay: "2.5s",
            }}
          ></div>
          <div
            className="star star-green"
            style={{
              animation: "blowStars 8s linear infinite",
              animationDelay: "1.5s",
            }}
          ></div>
        </>
      ))}
    </div>
  );
};

const Home = () => {
  return (
    <div>
      <div className="w-full h-fit flex flex-col items-center md:flex-row justify-evenly bg-gradient-to-r from-primaryBgColor to-swipeToBgColor overflow-x-hidden relative">
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
          {starsComponent()}
        </div>

        <div className="flex flex-col items-center justify-center mx-4 md:mx-8 md:ml-20 w-full md:w-1/2">
          <div className="">
            <p className="font-semibold text-primaryColor text-xl tracking-wide text-center md:text-center">
              Best spa Massage Parlour
            </p>
          </div>

          <div className="mt-5">
            <p className="text-3xl md:text-7xl text-center md:text-center font-semibold tracking-wider animate-pulse slide-in-scale-up">
              Elevate <span className="text-primaryColor">Your Body</span> And
              Mind With <span className="text-primaryColor">Our Spa</span>{" "}
              Massages
            </p>
          </div>

          <div className="flex flex-col md:flex-row gap-7 text-white mt-12 items-center md:items-start">
            <button className="transform hover:scale-95 transition duration-200 ease-in-out tracking-wider flex justify-center items-center gap-2 text-sm px-6 py-3 rounded-md font-bold bg-primaryColor slide-in-from-right-scale-up">
              EXPLORE SERVICES <FaArrowRight />
            </button>
          </div>
        </div>

        <div className="relative md:w-1/2 mt-8 md:mt-0 mx-4 md:mx-12 overflow-hidden">
          <img
            src={women}
            className="object-cover w-full h-[300px] md:h-[450px] mt-4 md:mt-12 circular-reveal-spin"
            alt="Women"
          />
        </div>
      </div>

      {/*Section 2*/}
      <div className="py-6 w-full h-fit flex flex-col md:flex-row justify-evenly bg-gradient-to-r from-gray-200 to-gray-100">
        <div className="flex flex-col items-center mx-auto">
          <div className="">
            <p className="text-primaryColor font-sm text-xl font-serif text-center md:text-left">
              What We do..?
            </p>
          </div>

          <div className="mt-4">
            <img src={shape} alt="Shape" />
          </div>

          <div className="text-black text-center md:text-left text-4xl font-serif my-4 tracking-wider">
            Professional Spa and Beauty Services
          </div>

          <Card />
        </div>
      </div>

      {/*Section 3*/}
      <div className="bg-gradient-to-r from-primaryBgColor to-swipeToBgColor">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center py-12">
            <div className="order-2 md:order-1">
              <div className="flex justify-center md:justify-start">
                <img src={massage} alt="Massage" className="w-full max-w-md h-auto" />
              </div>
              <div className="mt-8 flex justify-center md:hidden">
                <img src={girl} alt="Girl" className="w-48 h-auto" />
              </div>
            </div>

            <div className="order-1 md:order-2 text-center md:text-left">
              <div className="flex justify-center md:justify-start items-center gap-2">
                <div>About Us</div>
                <img src={rightarr} alt="Right Arrow" />
              </div>
              <h2 className="mt-4 text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9 md:text-4xl md:leading-10">
                Discover The <span className="text-primaryColor">Ultimate Spa</span> Massage Experience
              </h2>
              <p className="mt-4 max-w-2xl text-lg text-gray-500">
                Discover a world of tranquility, luxury, and personalized care as you embark on a journey of self-care and renewal. Visit us today and let us transport you to a realm of beauty and serenity.
              </p>
              <p className="mt-4 text-lg text-gray-500">
                We also offer private rooms for more intimate treatments and couples' packages for a romantic escape.
              </p>
              <div className="mt-8">
                <CTAButton active={true} linkto="./about">
                  <div className="flex items-center gap-3">
                    DISCOVER MORE <FaArrowRight />
                  </div>
                </CTAButton>
              </div>
            </div>

            <div className="hidden md:block md:col-start-1 md:row-start-1 md:relative">
              <img src={girl} alt="Girl" className="absolute w-48 h-auto right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2" />
            </div>
          </div>
        </div>
      </div>

      {/*Section 4*/}
      <div
        className="w-full h-fit
                  flex flex-col md:flex-row justify-evenly
                 bg-gradient-to-r from-gray-200 to-gray-100  gap-12 overflow-x-auto"
      >
        <div className="flex flex-col">
          <div className="mt-12 flex flex-col md:flex-row md:gap-12 lg:gap-36 ">
            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div className="md:mr-4">
                <img src={badge} alt="Badge" />
              </div>
              <div className="flex flex-col text-center md:text-left">
                <div>
                  <p className="text-5xl">26 +</p>
                </div>
                <div className="mt-2">
                  <p className="text-gray-500 text-md">Years of Experience</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div className="md:mr-4">
                <img src={leaf} alt="Leaf" />
              </div>
              <div className="flex flex-col text-center md:text-left">
                <div>
                  <p className="text-5xl">100 %</p>
                </div>
                <div className="mt-2">
                  <p className="text-gray-500 text-md">Natural Products</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div className="md:mr-4">
                <img src={like} alt="Like" />
              </div>
              <div className="flex flex-col text-center md:text-left">
                <div>
                  <p className="text-5xl">2000 +</p>
                </div>
                <div className="mt-2">
                  <p className="text-gray-500 text-md">Satisfied Clients</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center">
              <div className="md:mr-4">
                <img src={user} alt="User" />
              </div>
              <div className="flex flex-col text-center md:text-left">
                <div>
                  <p className="text-5xl">36 +</p>
                </div>
                <div className="mt-2">
                  <p className="text-gray-500 text-md">Specialists Team</p>
                </div>
              </div>
            </div>
          </div>

          <div className="items-center justify-center flex my-10">
            <div className="w-full  h-[2px] bg-primaryColor"></div>
            <img
              src={girls}
              alt="Girls"
              className="bg-red-200 w-24 rounded-full"
            />
            <div className="w-full  h-[2px] bg-primaryColor"></div>
          </div>

          <div>
            <div className="flex flex-col md:flex-col lg:flex-row justify-between items-center px-4">
              

              <div className="mt-4 md:mt-6 lg:mt-0">
                <p className="text-3xl md:text-4xl lg:text-5xl font-serif font-medium px-4">
                  Latest Project Gallery
                </p>
              </div>

              <div className="flex flex-wrap gap-4 justify-center items-center mt-4 md:mt-6">
                <button className="transform hover:scale-95 transition duration-200 ease-in-out tracking-wider flex justify-center items-center gap-2 text-sm md:text-base px-4 md:px-6 py-2 md:py-3 rounded-md font-bold bg-primaryColor text-white">
                  MASSAGE
                </button>
                <button className="transform hover:scale-95 transition duration-200 ease-in-out tracking-wider flex justify-center items-center gap-2 text-sm md:text-base px-4 md:px-6 py-2 md:py-3 rounded-md font-bold bg-primaryColor text-white">
                  WAXING
                </button>
                <button className="transform hover:scale-95 transition duration-200 ease-in-out tracking-wider flex justify-center items-center gap-2 text-sm md:text-base px-4 md:px-6 py-2 md:py-3 rounded-md font-bold bg-primaryColor text-white">
                  RELAXING
                </button>
                <button className="transform hover:scale-95 transition duration-200 ease-in-out tracking-wider flex justify-center items-center gap-2 text-sm md:text-base px-4 md:px-6 py-2 md:py-3 rounded-md font-bold bg-primaryColor text-white">
                  SKIN CARE
                </button>
              </div>
            </div>

            <div className="mt-12 mb-20">
              <Cards />
            </div>
          </div>
        </div>
      </div>

      {/*Section 4*/}
      <div>
        <Expert />
      </div>

      {/*Section 5*/}
      <div className="mt-36 bg-gradient-to-r from-gray-200 to-gray-100 overflow-x-hidden">
        <PriceCard />
      </div>

      {/* Section 7*/}
      <div className="mb-5">
        <CardSection />
      </div>

      {/* Section 8 */}
      <div className="flex flex-col md:flex-row justify-evenly items-center my-auto bg-gradient-to-r from-primaryBgColor to-swipeToBgColor w-full">
        <div className="mt-10 md:mt-20 mb-10 md:mb-20">
          <img
            src={brand_1}
            alt="Brand_1"
            className="w-full md:w-auto max-h-20 md:max-h-full"
          />
        </div>

        <div className="mt-10 md:mt-20 mb-10 md:mb-20">
          <img
            src={brand_2}
            alt="Brand_2"
            className="w-full md:w-auto max-h-20 md:max-h-full"
          />
        </div>

        <div className="mt-10 md:mt-20 mb-10 md:mb-20">
          <img
            src={brand_3}
            alt="Brand_3"
            className="w-full md:w-auto max-h-20 md:max-h-full"
          />
        </div>

        <div className="mt-10 md:mt-20 mb-10 md:mb-20">
          <img
            src={brand_4}
            alt="Brand_4"
            className="w-full md:w-auto max-h-20 md:max-h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
