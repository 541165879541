import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Service from './pages/Service';
import Footer from './components/Homepage/Footer';
import Navbar from './components/Homepage/Navbar';

const App = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/about"} element={<About />} />
        <Route path={"/service"} element={<Service />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
