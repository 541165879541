import React from "react";
import spa from "../../assets/spa.jpg";

import {
  FaArrowRight,
  FaFacebookSquare,
  FaInbox,
  FaInstagramSquare,
  FaLinkedinIn,
  FaPhoneSquare,
  FaSearchLocation,
  FaTwitterSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-topBarAndFooterColor">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 ml-2 md:ml-20 mr-2 md:mr-20">
        <div className="flex flex-col mt-16 mb-16">
          <div>
            <img src={spa} alt="logo" />
          </div>
          <div className="mt-4">
            <p className="text-white">
              Elevate Your Wellness Journey at Kaira Spa. Unwind, Relax and
              discover a haven of tranquility in the heart of Bhubaneswar
            </p>
          </div>
        </div>

        <div className="flex flex-col mt-16 mb-16 ml-7">
          <div>
            <p className="text-white text-3xl font-semibold">Quick Link</p>
          </div>
          <div className="flex flex-col mt-2">
            {/* <Link to="/about">
              <div className="flex flex-row items-center gap-6">
                <FaArrowRight className="text-white" />
                <p className="text-white">About Us</p>
              </div>
            </Link> */}
            <Link to="/service">
              <div className="flex flex-row items-center gap-6">
                <FaArrowRight className="text-white" />
                <p className="text-white">Our Services</p>
              </div>
            </Link>
            <Link to="/contact">
              <div className="flex flex-row items-center gap-6 mt-4">
                <FaArrowRight className="text-white" />
                <p className="text-white">Contact Us</p>
              </div>
            </Link>
          </div>
        </div>

        <div className="flex flex-col mt-16 mb-16">
          <div>
            <p className="text-white text-3xl font-semibold">Contact Details</p>
          </div>
          <div>
            <div className="flex flex-col mt-4">
              {/* <p className="text-white">Phone Number</p> */}
              <div className="flex flex-row items-center gap-5 mt-3">
                <FaPhoneSquare className="text-white h-10" />
                <p className="text-white">+91 8114882165</p>
              </div>
            </div>
            <div className="flex flex-col mt-4">
              {/* <p className="text-white">Email</p> */}
              <div className="flex flex-row items-center  gap-5 mt-3">
                <FaSearchLocation className="text-white h-10" />
                <p className="text-white">kairaspa2021@gmail.com</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-16 mb-16">
          <div>
            <p className="text-white text-3xl font-semibold">Availability</p>
          </div>
          <div className="mt-4">
            <p className="text-white">We Are Available:</p>
            <p className="text-gray-500"> Mon-Sun: 09.00 am to 10.00 pm</p>
          </div>

          <div>
            <p className="text-white text-3xl font-semibold mt-2">Address</p>
          </div>
          <div className="mt-4">
            <p className="text-white">Hotel Sandy Tower, XIMB Square, Nandankanan Rd, Gajapati Nagar, Bhubaneswar, Odisha 751013</p>
          </div>

          <div className="flex flex-row gap-6 mt-4">
            <Link
              to="https://www.facebook.com/kairaspabbsr?mibextid=ZbWKwL"
              target="_blank"
            >
              <FaFacebookSquare className="w-8 h-8" color="white" />
            </Link>
            <FaTwitterSquare className="w-8 h-8" color="white" />
            <FaLinkedinIn className="w-8 h-8" color="white" />
            <Link
              to="https://www.instagram.com/kaira_spa_21?igsh=eDRkeHJlZWk1NnJo"
              target="_blank"
            >
              <FaInstagramSquare className="w-8 h-8" color="white" />
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-yellow-800 to-yellow-600 flex justify-evenly items-center h-16">
        <div>
          <p className="text-white">
            "© {currentYear} Kaira Spa. All Rights Reserved."
          </p>
        </div>
        <div>
          <p className="text-white">
            {" "}
            Design & Develop by {" "}
            <Link
              to="https://webbocket.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-textwhite font-bold"
            >
              Web_Bocket Pvt. Ltd.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
