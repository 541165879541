import React from "react";
import smiley_girl from "../../assets/smiley-girl.png";
import barber from "../../assets/barber-1.jpg";
import team_1 from "../../assets/team_1.jpg";
import team_2 from "../../assets/team_2.jpg";
import team_3 from "../../assets/team_3.jpg";

const Expert = () => {
  return (
    <div class="flex items-center bg-indigo-100 w-screen min-h-screen" >
      <div class="container ml-auto mr-auto flex flex-wrap items-start">
        <div class="w-full pl-5 lg:pl-2 mb-4 mt-4">
          <h1 class="text-3xl lg:text-4xl text-gray-700 font-extrabold">
            Our Experience Specialist
          </h1>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img src={team_1}
                alt=""
                class="h-64 ml-auto mr-auto" />
            </figure>

            <div class="rounded-lg p-4 bg-purple-700 flex flex-col text-center">
              <div>
                <h5 class="text-white text-2xl font-bold leading-none">
                  Angelika
                </h5>
                <span class="text-sm font-semibold text-gray-400 leading-none">
                  Lead Aesthetician
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img src={barber}
                alt="imgs"
                class="h-64 ml-auto mr-auto" />
            </figure>
            <div class="text-center rounded-lg p-4 bg-[#4e2728] flex flex-col">
              <div>
                <h5 class="text-white text-2xl font-bold leading-none">
                  Shane swoyn
                </h5>
                <span class="text-sm font-semibold text-gray-400 leading-none">
                  Hair Style & Colourist
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img src={team_3}
                alt=""
                class="h-64 ml-auto mr-auto" />
            </figure>
            <div class="text-center rounded-lg p-4 bg-purple-600 flex flex-col">
              <div>
                <h5 class="text-white text-2xl font-bold leading-none">
                  Camila
                </h5>
                <span class="text-sm font-semibold text-gray-400 leading-none">
                  Massage Therapist
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
          <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
            <figure class="mb-2">
              <img src={team_2}
                alt="imgs"
                class="h-64 ml-auto mr-auto" />
            </figure>
            <div class="text-center rounded-lg p-4 bg-[#4e2728] flex flex-col">
              <div>
                <h5 class="text-white text-2xl font-bold leading-none">
                  Elena Rodriguez
                </h5>
                <span class="text-sm font-semibold text-gray-400 leading-none">
                  Wellness Coach
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expert;
