import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { motion } from "framer-motion";
import hamburger from "../../assets/hamburger.png";
import {
  FaInstagram,
  FaFacebook,
  FaYoutube,
  FaArrowRight,
} from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import spa from "../../assets/spa.jpg";
import CTAButton from "./Button";
import ModalForm from "../Modal/ModalForm";
import { MdOutlinePhone } from "react-icons/md";

const Navbar = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const animationProps = {
    initial: { x: "100%", opacity: 0 },
    animate: { x: 0, opacity: 1, transform: "translate3d(0,0,0)" },
    exit: { x: "100%", opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3, ease: "easeInOut" },
  };

  const animationPropss = {
    whileHover: {
      scale: 1.2, // Enlarge the item
      transition: { type: "spring", stiffness: 500, duration: 0.2 },
    },
  };

  const navItems = [
    { path: "/", label: "HOME" },
    { path: "/about", label: "ABOUT US" },
    { path: "/service", label: "SERVICES" },
    { path: "/contact", label: "CONTACT US" },
  ];

  return (
    <>
      <div className=' bg-topBarAndFooterColor md:flex hidden items-center justify-between px-10 py-2'>
        <div className='flex gap-6  text-white'>
          <p className='flex items-center gap-2 text-[14px] tracking-wider'>
            <MdOutlineMailOutline size={18} /> kairaspa2021@gmail.com
          </p>

          <p className='flex items-center gap-2 text-[14px] tracking-wider'>
            <MdOutlinePhone size={18} /> +91-8114882165
          </p>
        </div>

        <div className='flex items-center gap-3'>
          <div className='p-1 bg-primaryColor rounded-full cursor-pointer'>
            <Link to="https://www.instagram.com/kaira_spa_21?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target='_blank'>
              <FaInstagram size={14} color='white' />
            </Link>
          </div>

          <div className='p-1 bg-primaryColor rounded-full cursor-pointer'>
            <Link to="https://www.facebook.com/kairaspabbsr" target='_blank'>
              <FaFacebook size={14} color='white' />
            </Link>
          </div>

          {/* <div className='p-1 bg-primaryColor rounded-full cursor-pointer'>
            <FaYoutube size={14} color='white' />
          </div> */}

        </div>
      </div>

      <div className="bg-white shadow-lg py-4 px-10">
        <div className='m-auto  flex  items-center justify-between'>
          <div className="flex items-center">
            <img src={spa}
              alt="Logo"
              className="w-20 h-30" />
          </div>

          <div className='hidden lg:flex space-x-6'>
            {navItems.map((item, index) => (
              <motion.div
                key={index}
                className={`tracking-wider cursor-pointer pb-2 pt-2 ${location.pathname === item.path
                  ? "text-primaryColor border-b-2 border-primaryColor font-bold" // Active item styles
                  : "text-primaryTextColor hover:text-primaryColor border-b-2 border-transparent hover:border-primaryColor"
                  }`}
                onClick={() => navigate(item.path)}
                {...animationPropss}
              >
                {item.label}
              </motion.div>
            ))}
          </div>

          <div
            className=' hidden lg:flex space-x-8 tracking-wider flex-row gap-7 items-center 
                            justify-center text-white mr-6 mt-3  md:mb-0 md:mt-0'
            onClick={handleOpenModal}>
            <CTAButton active={true}>
              <div className='flex items-center gap-3'>
                APPOINTMENT
                <FaArrowRight />
              </div>
            </CTAButton>
          </div>

          <div
            onClick={() => setDrawerOpen(true)}
            className="text-center ml-2 lg:hidden flex">
            <motion.img
              animate={{
                scale: [1, 2, 2, 1, 1],
                rotate: [0, 0, 270, 270, 0],
                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
              }}
              className='w-[40px] h-[40px]' src={hamburger} alt="" />
          </div>
        </div>

        <div className={`border sidebar transition-transform transform ${isDrawerOpen ? "translate-x-0" : "translate-x-full"} fixed top-0 right-0 z-10 bg-white p-6 xl:w-[40%] lg:w-[50%] md:w-[60%] w-[100%] h-[100vh]`}>
          <div className={`fixed w-[100%]  top-0 right-0 h-full bg-white z-50 transform ease-in-out duration-500 transition-transform ${isDrawerOpen ? 'translate-x-0' : 'translate-x-full'} transition-all`}>
            <button className='m-5' onClick={() => setDrawerOpen(false)}><ImCross /></button>

            <div className=' ml-5 mr-5 grid grid-cols-1 gap-3'>
              <motion.div
                {...animationProps}
                className="lg:hidden  hover:ml-2 rounded-lg  flex items-center p-2 text-primaryTextColor text-[20px] border font-medium hover:text-primaryColor cursor-pointer transition-all duration-200 ease-in-out"
                onClick={() => {
                  setDrawerOpen(false)
                  navigate("/")
                }}>
                HOME
              </motion.div>

              <motion.div
                {...animationProps}
                className="lg:hidden  hover:ml-2 rounded-lg flex items-center p-2 text-primaryTextColor text-[20px] border font-medium hover:text-primaryColor cursor-pointer transition-all duration-200 ease-in-out"
                onClick={() => {
                  setDrawerOpen(false)
                  navigate("/about")
                }}>
                ABOUT US
              </motion.div>

              <motion.div
                {...animationProps}
                className="lg:hidden  hover:ml-2 rounded-lg flex items-center p-2 text-primaryTextColor text-[20px] border font-medium hover:text-primaryColor cursor-pointer transition-all duration-200 ease-in-out"
                onClick={() => {
                  setDrawerOpen(false)
                  navigate("/service")
                }}>
                SERVICES
              </motion.div>

              <motion.div
                {...animationProps}
                className="lg:hidden hover:ml-2 rounded-lg flex items-center p-2 text-primaryTextColor text-[20px] border font-medium hover:text-primaryColor cursor-pointer transition-all duration-200 ease-in-out"
                onClick={() => {
                  setDrawerOpen(false)
                  navigate("/contact")
                }}>
                CONTACT US
              </motion.div>

              <motion.div
                {...animationProps}
                className='lg:hidden hover:ml-2  flex items-center p-2  text-[20px]  font-medium hover:text-primaryColor cursor-pointer transition-all duration-200 ease-in-out'
                onClick={() => {
                  setDrawerOpen(false)
                  handleOpenModal(true)
                }}>

                <div
                  className='flex flex-row gap-7 items-center 
                                    justify-center text-white mr-6 mt-3  md:mb-0 md:mt-0'>
                  <CTAButton active={true}>
                    <div className='flex items-center gap-3'>
                      APPOINTMENT
                      <FaArrowRight />
                    </div>
                  </CTAButton>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div >

      <ModalForm isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default Navbar;
