import React from "react";
import { FaSearchLocation, FaPhoneSquare, FaClock } from "react-icons/fa";
import { FaAddressBook } from "react-icons/fa";

const Card = () => {
  return (
    <div>
      <div className="bg-primaryBgColor w-full md:w-[70%] mt-8 md:mt-24 mb-4 md:mb-16  md:ml-36 rounded-md">
        <div className="flex flex-col items-center justify-center mt-8 mb-8 mx-6">
          <div className="mt-6">
            <p className="font-serif text-3xl mb-4 text-center md:text-left tracking-wider">
              Our Contact Information
            </p>
          </div>
          <div className="mt-3">
            <p className="text-gray-600 text-center md:text-left tracking-wide">
              Have an inquiry or some feedback for us? Fill out the form below
              to contact our team.
            </p>
          </div>

          <div className="flex flex-col md:flex-col tracking-wide">
            <div className="bg-white rounded-md p-8 shadow-md mb-4 mt-8  md:mt-8  flex-shrink-0">
              <div className="flex flex-row items-center">
                <FaAddressBook className="text-2xl p-4 text-orange-600 h-40 w-40 mr-3 rounded-md" />
                <div className="flex flex-col">
                  <p className="font-semibold text-lg">Our Address</p>
                  <p className="text-gray-600">
                    Hotel Sandy Tower, XIMB Square, Nandankanan Rd, Gajapati
                    Nagar, Bhubaneswar, Odisha 751013
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-md p-8 shadow-md mb-4 flex-shrink-0 tracking-wide">
              <div className="flex flex-row items-center">
                <FaPhoneSquare className="text-2xl p-4 text-orange-600 h-20 w-20 mr-3 rounded-md" />
                <div className="flex flex-col">
                  <p className="font-semibold text-lg">Contact Details</p>
                  <p className="text-gray-600">+91-8114882165</p>
                  <p className="text-gray-600">kairaspa2021@gmail.com</p>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-md p-8 shadow-md mb-8 flex-shrink-0 tracking-wide">
              <div className="flex flex-row items-center">
                <FaClock className="text-2xl p-4 text-orange-600 h-20 w-20 mr-3 rounded-md" />
                <div className="flex flex-col">
                  <p className="font-semibold text-lg">Working Hours</p>
                  <p className="text-gray-600">Mon-Sun: 9:30 AM - 10:00 PM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
