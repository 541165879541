import React from 'react';
import CardSection from '../components/Servicepage/CardSection';
import { FaArrowRight } from 'react-icons/fa';
import headerImg from '../assets/saloonHeadImg.png';

const Service = () => {
    return (
        <div>
            <div
                className='h-[300px] bg-cover bg-no-repeat bg-gradient-to-r from-gray-300 to-gray-200 overflow-x-hidden'
                style={{ backgroundImage: `url(${headerImg})`}}>
                <div className='flex flex-col justify-center items-center lg:items-start mt-8 mx-4 lg:mt-28 lg:ml-24'>
                    <div>
                        <p className='text-4xl font-semibold text-center lg:text-left tracking-wider'>
                            Service Details
                        </p>
                    </div>
                    <div className='mt-6'>
                        <div className='flex flex-row items-center justify-center lg:justify-start gap-4'>
                            <p className='text-2xl'>Home</p>
                            <FaArrowRight />
                            <p className='text-2xl'>Service Detail</p>
                        </div>
                    </div>
                </div>
            </div>

            {/*Card section*/}
            <div>
                <CardSection />
            </div>
        </div>
    )
}

export default Service