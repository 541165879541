import React from "react";
import { FaArrowRight } from "react-icons/fa";
import shape from "../assets/shape.svg";

import headerImg from "../assets/saloonHeadImg.png";
import Card from "../components/Homepage/Card";
import Expert from "../components/Homepage/Expert";

const About = () => {
  return (
    <div className="overflow-x-hidden">
      <div
        className="h-[300px] bg-cover bg-no-repeat
                 bg-gradient-to-r from-gray-300 to-gray-200 
                overflow-x-hidden"
        style={{ backgroundImage: `url(${headerImg})` }}
      >
        <div
          className="flex flex-col justify-center items-center
                 lg:items-start mt-8 mx-4 lg:mt-28 lg:ml-24"
        >
          <div>
            <p className="text-4xl font-semibold text-center lg:text-left tracking-wider">
              About Us
            </p>
          </div>
          <div className="mt-6">
            <div
              className="flex flex-row items-center 
                        justify-center lg:justify-start gap-4"
            >
              <p className="text-2xl">Home</p>
              <FaArrowRight />
              <p className="text-2xl">About</p>
            </div>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="py-6 w-full h-fit flex flex-col md:flex-row justify-evenly bg-gradient-to-r from-gray-200 to-gray-100">
        <div className="flex flex-col items-center mx-auto">
          <div className="">
            <p className="text-primaryColor font-sm text-xl font-serif text-center md:text-left">
              What We do..?
            </p>
          </div>

          <div className="mt-4">
            <img src={shape} alt="Shape" />
          </div>

          <div className="text-black text-center md:text-left text-4xl font-serif my-4 tracking-wider">
            Professional Spa and Beauty Services
          </div>

          <Card />
        </div>
      </div>

      <Expert />
    </div>
  );
};

export default About;
