import React from 'react';
import Card from '../components/Contactpage/Card';
import { FaArrowRight } from 'react-icons/fa';
//import Map from '../components/Contactpage/Map';
import map from '../assets/map.png';
import headerImg from '../assets/saloonHeadImg.png';

const Contact = () => {
    return (
        <div>
            <div 
            className='h-[300px] bg-cover bg-no-repeat bg-gradient-to-r from-gray-100 to-gray-50 overflow-x-hidden'
            style={{ backgroundImage: `url(${headerImg})`}}>
                <div className='flex flex-col justify-center items-center lg:items-start mt-8 mx-4 lg:mt-28 lg:ml-24'>
                    <div>
                        <p className='text-4xl font-semibold text-center lg:text-left tracking-wider'>
                            Contact Us
                        </p>
                    </div>
                    <div className='mt-6'>
                        <div className='flex flex-row items-center justify-center lg:justify-start gap-4'>
                            <p className='text-2xl'>Home</p>
                            <FaArrowRight />
                            <p className='text-2xl'>Contact</p>
                        </div>
                    </div>
                </div>
            </div>

            {/*Section2*/}
            <div className='flex flex-col md:flex-row gap-32'>
                <div className='w-full'>
                    <Card />
                </div>
                <div className=' w-full  md:w-full'>
                     <img src={map} alt="Map"/>
                </div>
            </div>
        </div>
    )
}

export default Contact