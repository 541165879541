import React, { useRef, useState } from "react";
import { ImCross } from "react-icons/im";
// import emailjs from "@emailjs/browser";
import emailjs from "emailjs-com";
import { hasFormSubmit } from "@testing-library/user-event/dist/utils";

const ModalForm = ({ isOpen, onClose }) => {
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [placeError, setPlaceError] = useState(false);
  const [questionsError, setQuestionsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const obj = {
    name,
    email,
    phone,
    message,
  };

  // console.log(obj);

  const form = useRef();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log("Sending email...");
      const result = await emailjs.sendForm(
        "saikh-mirsat",
        "template_0w44via",
        form.current,
        "75y0iLRdVXUT5F4oX"
      );
      if (result?.status === 200) {
        alert("Your message has been delivered");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error sending form:", error);
      setLoading(false);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    console.log("handled submission");
  };
  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="container">
            <div className="flex justify-center">
              <div className="w-full max-w-2xl">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    {/* Background Image */}
                    <div
                      className="hidden md:block bg-cover bg-center bg-no-repeat h-80"
                      style={{
                        backgroundImage:
                          "url('https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp')",
                      }}
                    ></div>
                    {/* Modal Content */}
                    <form ref={form} onSubmit={HandleSubmit}>
                      <div className="flex items-center justify-center flex-col gap-5 px-4 mt-4">
                        <div className="w-full">
                          <input
                            name="name"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            id="formName"
                            className="form-input bg-gray-100 p-2 w-full rounded-md"
                            placeholder="Name"
                          />
                        </div>
                        <div className="w-full">
                          <input
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            id="formEmail"
                            className="form-input bg-gray-100 p-2 w-full rounded-md"
                            placeholder="Email address"
                          />
                        </div>
                        <div className="w-full">
                          <input
                            name="phone"
                            onChange={(e) => setPhone(e.target.value)}
                            type="text"
                            id="formPhone"
                            className="form-input bg-gray-100 p-2 w-full rounded-md"
                            placeholder="Phone"
                          />
                        </div>
                        <div className="w-full">
                          <textarea
                            name="message"
                            onChange={(e) => setMessage(e.target.value)}
                            type="text"
                            id="formMessage"
                            className="form-input bg-gray-100 p-2 w-full rounded-md"
                            placeholder="Message"
                          />
                        </div>
                        <button
                          type="submit"
                          className="bg-primaryColor text-white font-bold p-2 mb-4 rounded-xl"
                        >
                          {loading ? "Loading..." : "Send Message"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-600"
          >
            <ImCross size={24} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ModalForm;
