import React from "react";

const Tags = () => {
  return (
    <div className="bg-swipeToBgColor rounded-md p-4 md:py-8 md:px-8 shadow-md mb-4 mt-8">
      <div className="flex flex-col items-center">
        <p className="font-serif text-2xl mb-3">Tags</p>
        <div className="flex w-full mt-2 justify-center">
          <div className="w-full h-1 bg-black"></div>
        </div>
      </div>

      <div className="flex flex-col justify-center mt-3">
        <div className="mt-3 flex flex-wrap justify-center">
          <button
            className="bg-white text-gray-500 px-4 py-2 mx-2 
                    mb-2 transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
          >
            Massages
          </button>
          <button
            className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
          >
            Facials
          </button>
          <button
            className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
          >
            Scrub & Exfoliation
          </button>
        </div>

        <div className="mt-3 flex flex-wrap justify-center">
          <button
            className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
          >
            Hydrotherapy
          </button>
          <button
            className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2 
                    transition duration-300 ease-in-out hover:bg-primaryColor
                    hover:text-white hover:font-semibold"
          >
            Steam Room
          </button>
          <button
            className="bg-white text-gray-500 px-4 py-2 mx-2 mb-2
                     transition duration-300 ease-in-out hover:bg-primaryColor
                     hover:text-white hover:font-semibold"
          >
            Sauna
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tags;
